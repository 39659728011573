import React from "react";
import { StyleSheet, Text, View } from "react-native";
import "../fonts.css";

export default function GeneralCard(props) {
  const styles = StyleSheet.create({
    mainview: {
      marginTop: 100,
      marginLeft: 10,
      marginRight: 10,
      paddingBottom: 50,
      backgroundColor: props.backgroundColor,
      height: props.height,
      width: props.width,
      borderRadius: 20,
      padding: 30,
    },
    iconview: {
      width: 100,
      height: 100,
      borderRadius: 20,
    },

    textview: {
      width: "100%",
    },

    description: {
      color: "#fdfdfd",
      fontSize: 14,
      fontWeight: 400,
      textAlign: "left",
      width: "100%",
      marginTop: 10,
    },
    emoji: {
      height: 60,
      width: 60,
      borderRadius: 50,
      alignItems: "center",
      justifyContent: "center",
      paddingTop: 5,
      backgroundColor: props.emojicolor,
    },

    title: {
      textDecorationLine: "underline",
      color: props.color,
      fontSize: 20,

      paddingLeft: 5,
      textAlign: "left",

      fontWeight: 700,
    },

    for: {
      color: "#fdfdfd",
      fontSize: 20,
      textAlign: "left",

      fontWeight: 700,
    },
  });

  return (
    <View style={styles.mainview}>
      <View style={styles.iconview}>
        <View style={styles.emoji}>
          <Text style={{ fontSize: 35 }}>{props.emoji}</Text>
        </View>
      </View>
      <View style={styles.textview}>
        <View
          style={{
            flexDirection: "row",
            width: "100%",
            alignItems: "baseline",
          }}
        >
          <Text style={styles.for}>For</Text>

          <Text style={styles.title}>{props.title}</Text>
        </View>
        <Text style={styles.description}>{props.description}</Text>
      </View>
    </View>
  );
}
