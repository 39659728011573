import React from "react";
import { StyleSheet, Text, View, TouchableOpacity } from "react-native";

export default function Taskbar(props) {
  return (
    <View style={styles.mainview}>
      <View style={{ paddingBottom: 20, paddingRight: 20 }}>
        <img
          src={require("../../assets/images/blue_logo.png")}
          width="100"
          height="25"
          alt="logo"
        />
      </View>
      <TouchableOpacity
        onPress={() => {
          props.myRefNutritionist.current.scrollIntoView({
            behavior: "smooth",
          });
        }}
        style={{ flexDirection: "row" }}
      >
        <Text style={{ color: "#00397F", paddingHorizontal: 40, fontSize: 20 }}>
          For Nutritionists
        </Text>
      </TouchableOpacity>
      <TouchableOpacity
        style={{ flexDirection: "row" }}
        onPress={() => {
          props.myRefClient.current.scrollIntoView({
            behavior: "smooth",
          });
        }}
      >
        <Text style={{ color: "#00397F", paddingHorizontal: 40, fontSize: 20 }}>
          For Health Enthusiasts
        </Text>
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  mainview: {
    paddingTop: 20,
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    backgroundColor: "#fdfdfd",
    paddingLeft: 20,
  },
});
