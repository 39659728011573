import React from "react";
import { StyleSheet, Text, View } from "react-native";

export default function EmojiCard(props) {
  const styles = StyleSheet.create({
    mainview: {
      backgroundColor: "#E9EDED",
      height: props.height,
      width: props.width,
      borderRadius: 10,

      margin: props.margin,
      marginTop: props.marginTop,
      justifyContent: "center",
      alignItems: "center",
    },
  });

  return (
    <View style={styles.mainview}>
      <Text style={{ fontSize: props.fontSize }}>{props.emoji}</Text>
    </View>
  );
}
