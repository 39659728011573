import React, { useRef } from "react";
import { StyleSheet, View } from "react-native";
import Taskbar from "./components/taskbar/Taskbar";
import NutritionistPage from "./components/views/NutritionistPage";
import NutritionistPageMobile from "./components/views/NutritionistPageMobile";
import ClientPage from "./components/views/ClientPage";
import ClientPageMobile from "./components/views/ClientPageMobile";
import IntroPage from "./components/views/IntroPage";
import IntroPageMobile from "./components/views/IntroPageMobile";
import { isMobile } from "react-device-detect";

export default function App() {
  const myRefClient = useRef(null);
  const myRefNutritionist = useRef(null);
  if (!isMobile)
    return (
      <View style={styles.mainview}>
        <Taskbar
          myRefClient={myRefClient}
          myRefNutritionist={myRefNutritionist}
        ></Taskbar>
        <IntroPage></IntroPage>
        <div ref={myRefClient}>
          <ClientPage></ClientPage>
        </div>
        <div ref={myRefNutritionist}>
          <NutritionistPage></NutritionistPage>
        </div>
      </View>
    );

  return (
    <View style={styles.mainview}>
      <IntroPageMobile />
      <ClientPageMobile />
      <NutritionistPageMobile />
    </View>
  );
}

const styles = StyleSheet.create({
  mainview: {},
});
