import React from "react";
import { StyleSheet, Text, View } from "react-native";

import EmojiCard from "../emoji/EmojiCard";
import GeneralCard from "../cards/GeneralCard";

import TextAnimation from "react-text-animations";

import "../fonts.css";

export default function IntroPageMobile(props) {
  return (
    <View style={styles.mainview}>
      <View style={{ margin: 20 }}>
        <img
          src={require("../../assets/images/blue_logo.png")}
          width="100"
          height="25"
          alt="logo"
        />
      </View>

      <View
        style={{
          width: "100%",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "baseline",
          paddingLeft: 20,
          paddingVertical: 10,
        }}
      >
        <View style={{ flexDirection: "row", alignItems: "baseline" }}>
          <Text
            style={{
              fontWeight: 700,
              fontSize: 26,
              color: "#00397f",
            }}
          >
            Your
          </Text>

          <View style={{ paddingLeft: 10 }}>
            <TextAnimation.Slide
              cname="textanimated"
              animation={{
                duration: 1000,
                delay: 3000,
                timingFunction: "ease-out",
              }}
              target="Nutritionist"
              text={["Nutritionist", "Health Coach", "Personal Trainer"]}
            >
              Nutritionist
            </TextAnimation.Slide>
          </View>
        </View>

        <Text
          style={{
            fontSize: 26,
            color: "#00397f",
            paddingTop: 10,

            fontWeight: 700,
          }}
        >
          is a click away
        </Text>
        <Text
          style={{
            fontSize: 14,

            color: "#00397f",
            paddingTop: 30,

            fontWeight: 400,
            paddingLeft: 0,
            paddingBottom: 30,
            paddingRight: 30,
          }}
        >
          Buy Meal Plans, Subscribe To A Nutritionist And Start Your Dieting And
          Fitness Journey.
        </Text>
      </View>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "center",
          marginTop: 20,
        }}
      >
        <EmojiCard
          emoji="🍎"
          height={70}
          width={70}
          fontSize={40}
          marginTop={40}
          margin={10}
        ></EmojiCard>
        <EmojiCard
          emoji="💸"
          height={70}
          width={70}
          fontSize={40}
          margin={10}
        ></EmojiCard>
        <EmojiCard
          emoji="📅"
          height={70}
          width={70}
          fontSize={40}
          margin={10}
          marginTop={40}
        ></EmojiCard>
      </View>

      <View
        style={{
          alignItems: "center",
          paddingHorizontal: 20,
          paddingBottom: 30,
        }}
      >
        <GeneralCard
          emoji="👩‍⚕️"
          width="100%"
          height="auto"
          backgroundColor="#9EB7FF"
          color="#00397F"
          title="Professionals"
          description="All of your client management tools in one place"
          emojicolor="#00397F"
        />

        <GeneralCard
          emoji="💪"
          width="100%"
          height="auto"
          emojicolor="#9EB7FF"
          color="#9EB7FF"
          title="Fitness enthusiasts"
          description="Find a nutritionist, meal plans and recipes to help you on your fitness goal"
          backgroundColor="#00397F"
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  mainview: {
    flexDirection: "column",
    width: "100%",
    height: "300%",
    backgroundColor: "#fdfdfd",
  },
});
