import React from "react";
import { StyleSheet, Text, View } from "react-native";
import "../fonts.css";

export default function ClientCardMobile(props) {
  const styles = StyleSheet.create({
    mainview: {
      marginTop: 100,
      marginLeft: 10,
      marginRight: 10,
      backgroundColor: props.backgroundColor,
      height: props.height,
      width: 300,
      borderRadius: 20,
      alignItems: "space-between",
    },
    iconview: {
      height: 100,
      paddingTop: 30,
    },

    textview: {
      height: 150,
    },
  });

  return (
    <View style={styles.mainview}>
      <View style={styles.iconview}>
        <View
          style={{
            marginLeft: 20,
            backgroundColor: "#9EB7FF",
            borderTopLeftRadius: 30,
            borderTopRightRadius: 30,
            borderBottomRightRadius: 30,
            borderBottomLeftRadius: 10,
            height: 60,
            width: 60,
            paddingTop: 5,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Text style={{ fontSize: 35 }}>{props.emoji}</Text>
        </View>
      </View>
      <View style={styles.textview}>
        <Text
          style={{
            fontSize: 20,
            paddingHorizontal: 20,
            color: props.textColor,
            textAlign: "left",
            fontWeight: 700,
          }}
        >
          {props.title}
        </Text>
        <Text
          style={{
            fontSize: 14,
            paddingHorizontal: 20,
            textAlign: "left",
            marginTop: 10,
            color: props.textColor,
            fontWeight: 400,
          }}
        >
          {props.description}
        </Text>
      </View>
    </View>
  );
}
