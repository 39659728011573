import React from "react";
import { StyleSheet, Text, View } from "react-native";

import "../fonts.css";
import ClientCardMobile from "../cards/ClientCardMobile";

export default function ClientPageMobile(props) {
  return (
    <div ref={props.ref}>
      <View style={styles.mainview}>
        <View
          style={{
            alignItems: "center",
            justifyContent: "center",
            paddingTop: 40,
          }}
        >
          <Text
            style={{
              fontSize: 30,
              paddingHorizontal: 20,
              fontWeight: 700,
              textAlign: "center",
              color: "#fdfdfd",
            }}
          >
            Are you looking for a fitness guru?
          </Text>
        </View>

        <View
          style={{
            alignItems: "center",
            paddingHorizontal: 50,
          }}
        >
          <ClientCardMobile
            backgroundColor="#fdfdfd"
            textColor="#00397F"
            emoji="🍎"
            title="Subscribe to a Health Coach"
            description="Find a nutritionist, personal trainer, or health coach to guide you through your journey"
          />
          <View style={{ paddingTop: 50 }}>
            <ClientCardMobile
              textColor="#00397F"
              backgroundColor="#fdfdfd"
              emoji="📅"
              title="Buy Meal Plans"
              description="Is it Keto? Or do you need more carbs? Find meal plans that suit your diet regiment and preferences"
            />
          </View>
          <ClientCardMobile
            textColor="#00397F"
            backgroundColor="#fdfdfd"
            emoji="📈"
            title="Track your goals"
            description="Track and visualize all your macros and micros, sync your wearable and track your activity"
          />
        </View>
      </View>
    </div>
  );
}

const styles = StyleSheet.create({
  mainview: {
    backgroundColor: "#9EB7FF",
    paddingBottom: 100,
    width: "100%",
  },
});
