import React from "react";
import { StyleSheet, Text, View } from "react-native";
import TextAnimation from "react-text-animations";

import EmojiCard from "../emoji/EmojiCard";
import GeneralCard from "../cards/GeneralCard";
import "../fonts.css";

export default function IntroPage(props) {
  return (
    <View style={styles.mainview}>
      <View style={{ flexDirection: "row" }}>
        <View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "left",
              alignItems: "baseline",
              width: 600,
            }}
          >
            <Text
              style={{
                fontSize: 30,
                color: "#00397f",
                fontWeight: 700,
              }}
            ></Text>

            <TextAnimation.Slide
              cname="textanimated"
              animation={{
                duration: 1000,
                delay: 3000,
                timingFunction: "ease-out",
              }}
              target="Nutritionist"
              text={["Nutritionist", "Health Coach", "Personal Trainer"]}
            >
              Your Nutritionist is a click away
            </TextAnimation.Slide>

            <Text
              style={{
                color: "#00397f",

                fontWeight: 700,
                fontSize: 30,
              }}
            ></Text>
          </View>
          <Text
            style={{
              width: 350,
              fontSize: 18,
              paddingTop: 30,
              color: "#00397f",

              fontWeight: 400,
            }}
          >
            Buy meal plans, subscribe to a nutritionist and start your dieting
            and fitness journey.
          </Text>
        </View>
        <View style={{ flexDirection: "row", paddingLeft: 50 }}>
          <View>
            <EmojiCard
              emoji="🍎"
              height={150}
              width={160}
              fontSize={80}
              margin={20}
            ></EmojiCard>
            <EmojiCard
              emoji="💸"
              height={150}
              width={160}
              fontSize={80}
              margin={20}
            ></EmojiCard>
          </View>
          <View style={{ paddingTop: 80 }}>
            <EmojiCard
              emoji="📅"
              height={150}
              width={160}
              fontSize={80}
              margin={20}
            ></EmojiCard>
          </View>
        </View>
      </View>

      <View
        style={{
          flexDirection: "row",
          marginBottom: 100,
        }}
      >
        <GeneralCard
          emoji="👩‍⚕️"
          width={410}
          height={270}
          backgroundColor="#9EB7FF"
          color="#00397F"
          title="Professionals"
          description="All of your client management tools in one place"
          emojicolor="#00397F"
        />
        <View>
          <GeneralCard
            width={410}
            height={270}
            emoji="💪"
            emojicolor="#9EB7FF"
            color="#9EB7FF"
            title="Those looking to be healthier"
            description="Find a nutritionist, meal plans and recipes to help you on your fitness goal"
            backgroundColor="#00397F"
          />
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  mainview: {
    paddingTop: 100,
    paddingLeft: 100,
    backgroundColor: "#fdfdfd",
  },
});
