import React from "react";
import { StyleSheet, Text, View } from "react-native";
import "../fonts.css";
export default function NutritionistCard(props) {
  const styles = StyleSheet.create({
    mainview: {
      marginTop: props.marginTop,
      marginLeft: 10,
      marginRight: 10,
      paddingBottom: 50,
      backgroundColor: "#00397F",
      height: 200,
      width: 300,
      borderRadius: 20,
      flexDirection: "row",
      alignItems: "space-between",
    },
    iconview: {
      width: 100,
      alignItems: "center",
      justifyContent: "center",
      paddingLeft: 15,
      paddingBottom: 30,
    },

    textview: {
      flex: 1,
      width: 50,
      padding: 30,
      paddingLeft: 0,
      alignItems: "center",
      justifyContent: "center",
    },
  });

  return (
    <View style={styles.mainview}>
      <View style={styles.iconview}>
        <View
          style={{
            height: 50,
            width: 50,
            paddingTop: 5,
            paddingLeft: 10,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Text style={{ fontSize: 50 }}>{props.emoji}</Text>
        </View>
      </View>
      <View style={styles.textview}>
        <Text
          style={{
            color: "#fdfdfd",
            fontSize: 16,
            paddingRight: 40,
            paddingLeft: 15,
            textAlign: "left",
            marginTop: 50,

            fontWeight: 500,
          }}
        >
          {props.description}
        </Text>
      </View>
    </View>
  );
}
