import React from "react";
import { StyleSheet, Text, View } from "react-native";

import ClientCard from "../cards/ClientCard";
import "../fonts.css";

export default function ClientPage(props) {
  return (
    <div ref={props.ref}>
      <View style={styles.mainview}>
        <View
          style={{
            alignItems: "center",
            justifyContent: "center",
            paddingTop: 40,
          }}
        >
          <Text style={{ fontSize: 40, color: "#fdfdfd", fontWeight: 700 }}>
            Are you looking for a fitness guru?
          </Text>
        </View>

        <View
          style={{
            flexDirection: "row",
            justifyContent: "center",
            paddingHorizontal: 50,
          }}
        >
          <ClientCard
            textColor="#00397F"
            backgroundColor="#fdfdfd"
            height={350}
            emoji="🍎"
            title="Subscribe to a Health Coach"
            description="Find a nutritionist, personal trainer, or health coach to guide you through your journey"
          />
          <View style={{ paddingTop: 50 }}>
            <ClientCard
              textColor="#00397F"
              backgroundColor="#fdfdfd"
              height={350}
              emoji="📅"
              title="Buy Meal Plans"
              description="Is it Keto? Or do you need more carbs? Find meal plans that suit your diet regiment and preferences"
            />
          </View>
          <ClientCard
            textColor="#00397F"
            backgroundColor="#fdfdfd"
            height={350}
            emoji="📈"
            title="Track your goals"
            description="Track and visualize all your macros and micros, sync your wearable and track your activity"
          />
        </View>
      </View>
    </div>
  );
}

const styles = StyleSheet.create({
  mainview: {
    backgroundColor: "#9EB7FF",
    paddingBottom: 100,
  },
});
