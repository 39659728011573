import React from "react";
import { StyleSheet, Text, View } from "react-native";
import NutritionistCard from "../cards/NutritionistCard";
import "../fonts.css";

export default function NutritionistPage(props) {
  return (
    <div ref={props.ref}>
      <View style={styles.mainview}>
        <View
          style={{
            alignItems: "center",
            justifyContent: "center",
            paddingTop: 40,
          }}
        >
          <Text
            style={{
              fontSize: 40,
              color: "#171B29",

              fontWeight: 700,
            }}
          >
            Or do you want to scale your nutrition business?
          </Text>
        </View>

        <View
          style={{
            flexDirection: "row",

            justifyContent: "center",
            paddingHorizontal: 50,
          }}
        >
          <NutritionistCard
            marginTop={100}
            emoji="📊"
            description="Manage your client’s progress in one place."
          />
          <View style={{ paddingTop: 50 }}>
            <NutritionistCard
              marginTop={100}
              emoji="✏️"
              title="Buy Meal Plans"
              description="Create and sell subscriptions, meal plans and recipes."
            />
          </View>
          <NutritionistCard
            marginTop={100}
            emoji="💸"
            description="Monetize all of your creative efforts and scale up your brand"
          />
        </View>
        <View style={{ alignItems: "center", paddingTop: 100, width: "100%" }}>
          <Text
            style={{
              color: "#00397F",
              fontWeight: 500,
              paddingBottom: 0,
            }}
          >
            Follow us on Instagram for more!
          </Text>

          <View style={{ paddingVertical: 20 }}>
            <a href="https://www.instagram.com/bettr_ro/">
              <img
                src={require("../../assets/images/ig22.png")}
                width="30"
                height="30"
                alt="logo"
              />
            </a>
          </View>

          <img
            src={require("../../assets/images/blue_logo.png")}
            width="100"
            height="25"
            alt="logo"
          />

          <Text
            style={{
              color: "#00397F",
              fontWeight: 600,
              paddingTop: 30,
              fontSize: 18,
              paddingBottom: 30,
            }}
          >
            © 2022 Bettr. All rights reserved.
          </Text>
        </View>
      </View>
    </div>
  );
}

const styles = StyleSheet.create({
  mainview: {
    backgroundColor: "#fdfdfd",
  },
});
