import React from "react";
import { StyleSheet, Text, View } from "react-native";
import ClientCardMobile from "../cards/ClientCardMobile";
import "../fonts.css";

export default function NutritionistPage(props) {
  return (
    <div ref={props.ref}>
      <View style={styles.mainview}>
        <View
          style={{
            alignItems: "center",
            justifyContent: "center",
            paddingTop: 40,
          }}
        >
          <Text
            style={{
              fontSize: 30,
              textAlign: "center",
              color: "#00397F",

              fontWeight: 700,
            }}
          >
            Or do you want to scale your nutrition business?
          </Text>
        </View>

        <View
          style={{
            alignItems: "center",
            paddingHorizontal: 50,
          }}
        >
          <ClientCardMobile
            textColor="#fdfdfd"
            backgroundColor="#00397F"
            marginTop={50}
            emoji="📊"
            title="Data and Progress tracking"
            description="Manage your client’s progress in one place."
          />

          <ClientCardMobile
            textColor="#fdfdfd"
            backgroundColor="#00397F"
            marginTop={50}
            emoji="✏️"
            title="Subscriptions"
            description="Create and sell subscriptions, meal plans and recipes."
          />

          <ClientCardMobile
            textColor="#fdfdfd"
            backgroundColor="#00397F"
            marginTop={50}
            emoji="💸"
            title="Monetize your content"
            description="Monetize all of your creative efforts and scale up your brand"
          />
        </View>
        <View style={{ alignItems: "center", paddingTop: 50, width: "100%" }}>
          <Text
            style={{
              color: "#00397F",
              fontWeight: 500,
              paddingBottom: 30,
            }}
          >
            Follow us on Instagram for more!
          </Text>

          <View style={{ paddingVertical: 20 }}>
            <a href="https://www.instagram.com/bettr_ro/">
              <img
                src={require("../../assets/images/ig22.png")}
                width="30"
                height="30"
                alt="logo"
              />
            </a>
          </View>

          <img
            src={require("../../assets/images/blue_logo.png")}
            width="100"
            height="25"
            alt="logo"
          />

          <Text
            style={{
              color: "#00397F",
              fontWeight: 600,
              paddingTop: 30,
              fontSize: 18,
              paddingBottom: 30,
            }}
          >
            © 2022 Bettr. All rights reserved.
          </Text>
        </View>
      </View>
    </div>
  );
}

const styles = StyleSheet.create({
  mainview: {
    backgroundColor: "#fdfdfd",
  },
});
